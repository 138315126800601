<template src="./ModalDescriptionFile.html"></template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "modal-description-file",
  props: {
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      infoFile: {},
    };
  },
  validations: {
    infoFile: {
      description: { required },
      file: { required },
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    uploadInfo() {
      this.$emit("uploadFileInfo", this.infoFile);
      this.infoFile = {};
    },
  },
};
</script>

<style lang="scss" scoped src="./ModalDescriptionFile.scss">
</style>