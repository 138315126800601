<template src="./GroupCreation.html"></template>

<script>
import categoryService from "@/services/Category";
import { putFile } from "@/services/files";
import idiService from "../../services/Idi";
import ModalDescriptionFile from "../../components/ModalDescriptionFile/ModalDescriptionFile";
import { required } from "vuelidate/lib/validators";

export default {
  name: "groupCreation",
  components: {
    ModalDescriptionFile,
  },
  data() {
    return {
      creationRequest: { image: "" },
      categories: [],
      modalCreationRequest: false,
      showModalImageDescription: false,
      institutions: [],
      nameLeadResearchProfessor: "",
      institutionName: "",
      imageFile: null,
    };
  },
  validations: {
    creationRequest: {
      category: { required },
      description: { required },
      institution: { required },
      leadResearchProfessor: { required },
      nameOfTheResearchGroup: { required },
    },
    imageFile: { required },
  },
  created() {
    this.getInfoCategories();
    this.getListInstitutions();
    this.institutionName = this.$store.state.User.user.institutionId.name;
    this.nameLeadResearchProfessor = this.$store.state.User.user.name;
    this.creationRequest.leadResearchProfessor = this.$store.state.User.user.id;
  },
  methods: {
    deleteFile() {
      this.imageFile = null;
    },
    async getInfoCategories() {
      try {
        this.categories = await categoryService.getCategories();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: groupCreation/getInfoCategories()", e);
      }
    },
    async getListInstitutions() {
      try {
        this.institutions = await idiService.listMyInstitutions();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: ChooseUniversity/getListInstitutions()", e);
      }
    },
    async sendRequest() {
      this.$store.dispatch("fetchLoading", true);
      let urlImage = null;
      try {
        urlImage = await putFile(
          this.imageFile,
          "groupCreation",
          `${this.imageFile.name}`
        );
        this.creationRequest.image = urlImage;
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Error cargando el archivo",
          position: "is-bottom",
          type: "is-danger",
        });
      }
      try {
        const infoCreation = {
          typeRequest: "create",
          groupUpdate: {
            name: this.creationRequest.nameOfTheResearchGroup,
            institutionId: this.creationRequest.institution.toString(),
            institutionName: this.institutionName,
            categoryId: this.creationRequest.category,
            coordinatorId: this.creationRequest.leadResearchProfessor,
            description: this.creationRequest.description,
            fileImg: this.creationRequest.image,
            descriptionImg: this.creationRequest.descriptionImg,
          },
          userId: Number(this.creationRequest.leadResearchProfessor),
        };
        await idiService.groupChangeRequest(infoCreation);
        this.$store.dispatch("fetchLoading", false);
        this.modalCreationRequest = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Solicitud enviada",
          position: "is-bottom",
          type: "is-success",
        });
        this.$router.push({ path: "idiProfesorInvestigador" }).catch(() => {});
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: groupCreation/sendRequest()", e);
      }
    },
    openModalImageDescription() {
      this.showModalImageDescription = true;
    },
    closeModalDescriptionImage() {
      this.showModalImageDescription = false;
    },
    uploadFileInfo(info) {
      this.creationRequest.descriptionImg = info.description;
      this.imageFile = info.file;
      this.showModalImageDescription = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./GroupCreation.scss"></style>